@use '@angular/material' as mat;

mat.$theme-ignore-duplication-warnings: true;
/**
* Created at 1397/12/14 (2019/3/5).
* @author {@link https://mirismaili.github.io S. Mahdi Mir-Ismaili}
*/


// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

@mixin custom-tabs-theme() {

}

$my-font-family: 'Roboto Flex';

$my-custom-typography-config: mat.m2-define-typography-config(
	$font-family: $my-font-family,
);

@mixin define-css-classes($theme) {
	@include mat.all-component-themes($theme);

	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);

    $bgColorCard: map-get(map-get($theme, background), card);
    $bgColor: map-get(map-get($theme, background), background);

	.mat-h1 {
		font-family: $my-font-family;
	}
	.mat-h2 {
		font-family: $my-font-family;
	}
	.body-1 {
		font-family: $my-font-family;
	}
	div.our-tab-chip {
		margin-left: 1em;
		padding-left: .5em;
		padding-right: .5em;
		border-radius: 7px;
		color: white;
		background-color: rgba(0, 25, 50, 0.8);
	}
    .mat-mdc-raised-button {
        //border-radius: 24px;
        padding-left: 15px;
        margin-left: 3px;
        margin-right: 3px;
        //line-height: 30px;
        line-height: 1.2em;

        mat-icon {
            font-size: 18px;
        }
        :hover {
            opacity: .75;
        }
    }

    .mat-mdc-input-element::-webkit-calendar-picker-indicator {
        display: block;
    }

    div.menuItem:hover {
        //@debug "foreground: " $bgColor;
        // background-color: darken($bgColor, 10%);
        background-color: darken($bgColorCard,10%);
    }
    div.selectedMenuItem {
        background-color: darken($bgColorCard, 10%);
    }

    .mdc-tab--active {
        margin-left: 1px;
        border-bottom: 0px solid background;
		// background-color: rgba(242, 245, 245, .8);
        background-color: #{$bgColorCard} !important;
        opacity: 1;
        font-weight: 500;
    }

    .mat-mdc-tab  {
        box-shadow: 0 2px 2px rgb(0 0 0 / 24%), 0 0 2px rgb(0 0 0 / 12%);
        background-color: darken($bgColorCard, 5%);
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: lightgrey; //green !important;// rgba(0, 0, 0, 0.38);
    }
    /*
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
      border-color: blue !important; //rgba(0, 0, 0, 0.87);
    }
*/
    .mat-mdc-tab-body-wrapper {
        box-shadow: 0 2px 2px rgb(0 0 0 / 24%), 0 0 2px rgb(0 0 0 / 12%);
        //background-color: darken($bgColorCard,10%);
        //background-color: darken($bgColor, 2%); //WAS THIS ONE
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        //padding-left: 10px;
        //padding-right: 10px;
        overflow:hidden;
        //position: relative;
    }

    mat-sidenav-content {
       // AND THIS ONE background-color: darken($bgColorCard,10%);
    }

    mat-tab-group.innerTabs {
        .mat-mdc-tab-body-wrapper {
            box-shadow: none;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    div.chatContent {
        //background-color: rgb(253, 255, 255);//rgba(243, 242, 241, 1);
        //background-color: aqua;

        //@debug "foreground: #{$bgColor}";
        background-color: #{$bgColor};
    }
    div.chatItemContent {
        background-color: #{$bgColorCard};
    }

  // CSS THEME-DEPENDENT-STYLES ARE HERE:
  	.theme-dependent-colors {
    	background: mat.m2-get-color-from-palette($primary);
    	color: mat.m2-get-color-from-palette($accent);
  	}
  	.primary-background-color {
		background: mat.m2-get-color-from-palette($primary);
    	color: mat.m2-get-color-from-palette($primary, default-contrast);
	}

    .dialog-banner {
        background: mat.m2-get-color-from-palette($primary);
        color: mat.m2-get-color-from-palette($primary, default-contrast);
        font-family: 'roboto flex';
        padding: 24px;
        margin-bottom: 0px;
        line-height: 1em;
    }
  	.primary-font-color {
		color: mat.m2-get-color-from-palette($primary);
	}
	.accent-font-color {
		color: mat.m2-get-color-from-palette($primary);
	}
}

/**
* Define your custom themes in this map.
* The `key` of each member is the name of CSS class for that theme.
* To better understanding the schema of the map, see `@each` loop below and especially pay attention to
* `map-has-key()` functions.
*/
$app-themes: (
    traffic-light:  (primary-base: mat.$m2-teal-palette, accent-base: mat.$m2-amber-palette),
	indigo-pink : (primary-base: mat.$m2-indigo-palette, accent-base: mat.$m2-pink-palette),
	deeppurple-amber: (primary-base: mat.$m2-deep-purple-palette, accent-base: mat.$m2-amber-palette),
	pink-bluegrey : (primary-base: mat.$m2-pink-palette, accent-base: mat.$m2-blue-gray-palette),
	purple-green : (primary-base: mat.$m2-purple-palette, accent-base: mat.$m2-green-palette),
    brown-grey:  (primary-base: mat.$m2-brown-palette, accent-base: mat.$m2-grey-palette),
);

@each $css-class, $theme in $app-themes {

	$primary: if(map-has-key($theme, primary), map-get($theme, primary), mat.m2-define-palette(map-get($theme, primary-base)));

	$accent: if(map-has-key($theme, accent), map-get($theme, accent), mat.m2-define-palette(map-get($theme, accent-base)));

	$warn: if(map-has-key($theme, warn), map-get($theme, warn), mat.m2-define-palette(
			if(map-has-key($theme, warn-base), map-get($theme, warn-base), mat.$m2-red-palette)
	));

    //@debug "foreground: #{$theme}";
    //$background: map-get($theme, background);
    //$bgColor: map-get(map-get($theme, background), background);

	.#{$css-class} {
		/** Without overriding typography
		@include define-css-classes(
			mat.define-light-theme( $primary, $accent, $warn)
		);
		*/
        @include define-css-classes(
            mat.m2-define-light-theme(
                (color: (primary: $primary, accent: $accent, warn: $warn),
                typography: $my-custom-typography-config,
            )
        ));

        .our-chips {
            //background-color: white !important; //rgba(0, 25, 50, 0.3) !important;
        }

        div.primaryColor {
            background-color: mat.m2-get-color-from-palette($primary);
        }

		mat-toolbar.baseToolbar {
			//background-color: rgba(242, 245, 245); //rgba(243, 242, 241, 1);
			// background-color: rgba(243, 242, 241, 1); // greys
			// background-color: rgb(250, 235, 215, .5); // antique
			// background-color: rgba(0, 25, 50, 0.2);
			background-color: mat.m2-get-color-from-palette($primary);
            color: white;
            height: 63px;
			//border-bottom: 1px solid white;
		}

        div.confirmDialog {
            background-color: whitesmoke;
        }
        /*
		mat-expansion-panel.basePage{
			background-color: rgba(243, 242, 241, 1);  //rgba(255, 253, 246, 1); //rgba(249, 248, 247, 1); // rgba(255, 253, 246, 1);
		}
        */
		/*
		table.formGrid {
			background-color: rgba(242, 245, 245, .8);
			border: 1px solid;
		}
*/
		table.grid {
			background-color: rgba(242, 245, 245, .8);//rgba(243, 242, 241, 1); //rgba(245, 244, 243, 1);
			th {
				background-color: rgba(252, 255, 255, 1);
				border-top: 1px solid whitesmoke;
			}

            div.mat-mdc-select-trigger {
                background-color:white; //rgba(250, 249, 248, 1);
                padding: 3px;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 6px;
                &:disabled {
                    background-color: rgba(242, 245, 245, .8)
                }
                &:hover {
                    border: 1px solid black
                }
            }
            mat-select.error div.mat-mdc-select-trigger {
                border: 1px solid red;
            }
            input {
                background-color:white; //rgba(250, 249, 248, 1);
                border: 1px solid white;
                padding: 3px;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 6px;
                &:disabled {
                    background-color: rgba(242, 245, 245, .8)
                }
                &:read-only {
                    background-color: rgba(242, 245, 245, .8)
                }
                &:hover {
                    border: solid 1px black;
                }
                &.error {
                  border:solid 1px red;;
                }
            }
		}
        tr.mat-mdc-row.boldened {
            background-color: whitesmoke;
        }
        div.filters {
			background-color: rgba(240, 239, 238, 1); //rgba(242, 245, 245, .8); //rgba(243, 242, 241, 1);
        }
        table.filters {
			background-color: rgba(240, 239, 238, 1); //rgba(242, 245, 245, .8); //rgba(243, 242, 241, 1);
        }
		table.ouromc-table {
            .odd {
                background-color: rgba(242, 245, 245, .8); //rgba(243, 242, 241, 1);
            }
            .even {
                background-color: white;
            }
			th {
				background-color:aliceblue;
				border-top: 1px solid whitesmoke;
                font-weight: 600;
			}
            & tr.mat-mdc-row:hover {
                background-color: beige;
            }
		}
        table.balances {
            th {
                background-color: rgba(252, 255, 255, 1);
            }
        }
		mat-sidenav.ouromc-sidenav, mat-expansion-panel.ouromc-sidenav {
			background-color: rgba(240, 239, 238, 1);
			border-right: none;
		}
		/*
		mat-sidenav.ouromc-sidenav {
			background-color: rgba(240, 239, 238, 1)
		}
		mat-expansion-panel.ouromc-sidenav {
			background-color: rgba(240, 239, 238, 1)
		}
		*/
	}

	.#{$css-class}-dark {
		/** Without overriding typography
		@include define-css-classes(
			mat.define-dark-theme($primary, $accent, $warn)
		);
		*/

		@include define-css-classes(
			mat.m2-define-dark-theme(
				(color: (primary: $primary, accent: $accent, warn: $warn),
				typography: $my-custom-typography-config,
			)
		));
        a:link {
            color: lightskyblue;
        }

        a:visited {
            color: skyblue;
        }

        a:hover {
            color: #ffffff;
        }

        a:active {
            color: #ff4040;
            text-decoration: none;
            font-weight: normal;
        }
        table.ouromc-table {
            .even {
                background-color: black;
            }
            & tr.mat-mdc-row:hover {
                background-color: rgb(64,11,16);
            }
        }
        table.balances {
            th {
                background-color: black;
            }
        }

		table.grid {
            input {
                background-color:rgba(0,0,0, .87);
                border: 1px solid rgba(0,0,0, .87);
                color: white;
                padding: 2px;
                border-radius: 6px;
                &:disabled {
                    background-color: rgba(242, 245, 245, .8)
                }
                &:read-only {
                    background-color: rgba(242, 245, 245, .8)
                }
                &:hover {
                    border: solid 1px white;
                }
                &.error {
                  border:solid 1px red;;
                }
            }
		}
	}
	.theme-primary.#{$css-class} {
		background-color: mat.m2-get-color-from-palette($primary);
	}

	.theme-accent.#{$css-class} {
		background-color: mat.m2-get-color-from-palette($accent);
	}

	.theme-warn.#{$css-class} {
		background-color: mat.m2-get-color-from-palette($warn);
	}

}
